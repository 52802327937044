<template>
  <div class="list">
    <list-search @onSearch="onSearch" @clearSearch="clearSearch" :childData="childData"></list-search>
    <global-table :tableField="tableField" @tableAction="tableAction" :tableData="tableData" :paginationData="paginationData" @pageChange="pageChange"
    ></global-table>
  </div>
</template>

<script>
import Component from '@/assets/js/components.js'
import { SearchList, SearchData, tableField, tableData } from './js/setting.js'
import { request } from '@/assets/js/http.js'
import dayjs from 'dayjs'
export default {
  components: Component.components,
  name: 'HairPurchaserDemand',
  data () {
    var pageSize = parseInt((document.documentElement.clientHeight - 330) / 42)
    if (pageSize < 10) {
      pageSize = 10
    }
    return {
      tableField: tableField,
      tableData: tableData,
      pageNum: 1,
      paginationData: {
        pageSize: pageSize,
        total: 0
      },
      childData: { searchList: SearchList, searchData: SearchData }
    }
  },
  created () {
    this.myBidData()
  },

  methods: {
    // 获取采购交付-直发送货需求列表数据
    myBidData () {
      let sdksTime = ''
      let sdjsTime = ''
      if (this.childData.searchData.sdData) {
        sdksTime = dayjs(this.childData.searchData.sdData[0]).format('YYYY-MM-DD')
        sdjsTime = dayjs(this.childData.searchData.sdData[1]).format('YYYY-MM-DD')
      }
      let xfksTime = ''
      let xfjsTime = ''
      if (this.childData.searchData.xfData) {
        xfksTime = dayjs(this.childData.searchData.xfData[0]).format('YYYY-MM-DD')
        xfjsTime = dayjs(this.childData.searchData.xfData[1]).format('YYYY-MM-DD')
      }
      const obj = {
        pageNum: this.pageNum,
        pageSize: this.paginationData.pageSize,
        ...this.childData.searchData,
        sdksTime: sdksTime,
        sdjsTime: sdjsTime,
        releaseBeginTime: xfksTime,
        releaseEndTime: xfjsTime,
        confirmFlag: 0
      }
      request('/api/livraison/direct/getLivraisonDirectDataListByUserInfo', 'POST', obj).then((res) => {
        if (res.code === '200') {
          this.tableData = res.data.records
          if (this.paginationData.total !== res.data.total) {
            this.paginationData.total = res.data.total
          }
        }
      })
    },
    // 点击操作里的按钮进行操作
    tableAction: function (index, data, clickBtn) {
      if (clickBtn.operationButton === 'see') {
        this.$router.push({ params: { viewName: 'HairPurchaserDetails' }, query: { demandCode: data.demandCode } })
      }
    },
    // 查询
    onSearch (data) {
      Object.keys(data).forEach((key) => {
        if (data[key] === 'All' || data[key] === '') {
          delete data[key]
        }
      })
      this.pageNum = 1
      this.myBidData()
    },
    // 清空
    clearSearch (data) {
      Object.keys(data).forEach((key) => {
        if (data[key] === 'All' || data[key] === '') {
          delete data[key]
        }
      })
      this.pageNum = 1
      this.myBidData()
    },
    // 分页
    pageChange (data) {
      this.pageNum = data
      this.myBidData()
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/css/tablePadding.scss';
</style>
